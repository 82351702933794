.analytical-dashboard {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .analytical-dashboard .div {
    background-color: #f2f2f2;
    border: 1px none;
    height: 1024px;
    position: relative;
    width: 1440px;
  }
  
  .analytical-dashboard .frame {
    background-color: #ffffff;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: #d9d9d9;
    box-shadow: 0px 4px 4px #00000040;
    height: 1344px;
    left: 720px;
    overflow: hidden;
    position: absolute;
    top: -624px;
    transform: rotate(90deg);
    width: 96px;
  }
  
  .analytical-dashboard .overlap-group {
    height: 97px;
    left: 22px;
    position: absolute;
    top: 57px;
    width: 37px;
  }
  
  .analytical-dashboard .frame-wrapper {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 37px;
  }
  
  .analytical-dashboard .frame-2 {
    height: 24px;
    position: relative;
    transform: rotate(-90deg);
    width: 24px;
  }
  
  .analytical-dashboard .text-wrapper {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 24px;
    font-weight: 300;
    left: -16px;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 44px;
    transform: rotate(-90deg);
    white-space: nowrap;
  }
  
  .analytical-dashboard .welcome-thierno {
    color: #000000;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 36px;
    font-weight: 700;
    left: -117px;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    text-shadow: 0px 4px 4px #00000040;
    top: 1119px;
    transform: rotate(-90deg);
    white-space: nowrap;
  }
  
  .analytical-dashboard .vector {
    height: 22px;
    left: 44px;
    position: absolute;
    top: 170px;
    transform: rotate(-90deg);
    width: 23px;
  }
  
  .analytical-dashboard .group {
    height: 50px;
    left: 314px;
    position: absolute;
    top: 928px;
    width: 812px;
  }
  
  .analytical-dashboard .frame-3 {
    align-items: flex-start;
    display: inline-flex;
    gap: 14px;
    left: 0;
    position: absolute;
    top: 0;
  }
  
  .analytical-dashboard .flexcontainer-wrapper {
    align-items: center;
    background-color: #ffffff;
    border-radius: 40px;
    display: flex;
    gap: 18px;
    justify-content: space-around;
    overflow: hidden;
    padding: 13px 20px;
    position: relative;
    width: 131px;
  }
  
  .analytical-dashboard .flexcontainer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 97px;
  }
  
  .analytical-dashboard .text {
    align-self: stretch;
    color: #1e1e1e;
    font-family: "Montserrat-SemiBold", Helvetica;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 8.9px;
    position: relative;
    text-align: center;
  }
  
  .analytical-dashboard .span {
    color: #1e1e1e;
    font-family: "Montserrat-SemiBold", Helvetica;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 8.9px;
  }
  
  .analytical-dashboard .frame-4 {
    align-items: center;
    background-color: #ffffff;
    border-radius: 40px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    padding: 13px 20px;
    position: relative;
    width: 138px;
  }
  
  .analytical-dashboard .img {
    height: 24px;
    position: relative;
    width: 24px;
  }
  
  .analytical-dashboard .type-card {
    color: #1e1e1e;
    font-family: "Montserrat-SemiBold", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 8.9px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .analytical-dashboard .frame-5 {
    align-items: center;
    background-color: #ffffff;
    border-radius: 40px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    padding: 13px 20px;
    position: relative;
    width: 113px;
  }
  
  .analytical-dashboard .type-card-2 {
    color: #1e1e1e;
    flex: 1;
    font-family: "Montserrat-SemiBold", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 8.9px;
    position: relative;
  }
  
  .analytical-dashboard .frame-6 {
    align-items: flex-start;
    display: inline-flex;
    gap: 14px;
    left: 428px;
    position: absolute;
    top: 0;
  }
  
  .analytical-dashboard .frame-7 {
    align-items: center;
    background-color: #ffffff;
    border-radius: 40px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    padding: 13px 20px;
    position: relative;
    width: 87px;
  }
  
  .analytical-dashboard .frame-8 {
    align-items: center;
    background-color: #ffffff;
    border-radius: 40px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    padding: 13px 20px;
    position: relative;
    width: 131px;
  }
  
  .analytical-dashboard .emoji-earth-globe {
    height: 21.93px;
    position: relative;
    width: 21.93px;
  }
  
  .analytical-dashboard .icon-location {
    height: 22.15px;
    position: relative;
    width: 22.15px;
  }
  
  .analytical-dashboard .frame-9 {
    align-items: flex-start;
    background-color: #ffffff;
    border-radius: 24px;
    box-shadow: var(--general-shadows-shadow-01);
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 183px;
    justify-content: center;
    left: 119px;
    overflow: hidden;
    padding: 24px 30px;
    position: absolute;
    top: 146px;
    width: 409px;
  }
  
  .analytical-dashboard .frame-10 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    position: relative;
  }
  
  .analytical-dashboard .type-card-3 {
    color: #7c7c7c;
    font-family: "General Sans-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    height: 12.65px;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: 76.41px;
  }
  
  .analytical-dashboard .card-title {
    color: #1e1e1e;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    height: 18.97px;
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    white-space: nowrap;
    width: 161.44px;
  }
  
  .analytical-dashboard .amount-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 6px;
    position: relative;
  }
  
  .analytical-dashboard .amount {
    color: #1e1e1e;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 52px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .analytical-dashboard .group-2 {
    height: 82px;
    left: 190px;
    position: absolute;
    top: 51px;
    width: 178px;
  }
  
  .analytical-dashboard .overlap {
    background-color: #ffffff;
    border-radius: 24px;
    box-shadow: 0px 2px 6px #0d0a2c14;
    height: 769px;
    left: 974px;
    position: absolute;
    top: 136px;
    width: 451px;
  }
  
  .analytical-dashboard .divider {
    height: 1px;
    left: 14px;
    position: absolute;
    top: 52px;
    width: 390px;
  }
  
  .analytical-dashboard .card-info {
    height: 17px;
    left: 147px;
    position: absolute;
    top: 18px;
    width: 148px;
  }
  
  .analytical-dashboard .type-card-4 {
    color: #969696;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 0;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .analytical-dashboard .group-3 {
    height: 621px;
    left: 12px;
    position: absolute;
    top: 94px;
    width: 369px;
  }
  
  .analytical-dashboard .text-wrapper-2 {
    color: #000000;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .analytical-dashboard .text-wrapper-3 {
    color: #000000;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 68px;
    white-space: nowrap;
  }
  
  .analytical-dashboard .text-wrapper-4 {
    color: #000000;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 136px;
    white-space: nowrap;
  }
  
  .analytical-dashboard .text-wrapper-5 {
    color: #000000;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 204px;
    white-space: nowrap;
  }
  
  .analytical-dashboard .text-wrapper-6 {
    color: #000000;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 272px;
    white-space: nowrap;
  }
  
  .analytical-dashboard .text-wrapper-7 {
    color: #000000;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 340px;
    white-space: nowrap;
  }
  
  .analytical-dashboard .sending-to-multiple {
    color: #000000;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 408px;
    white-space: nowrap;
  }
  
  .analytical-dashboard .text-wrapper-8 {
    color: #000000;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 476px;
    white-space: nowrap;
  }
  
  .analytical-dashboard .text-wrapper-9 {
    color: #000000;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 544px;
    white-space: nowrap;
  }
  
  .analytical-dashboard .text-wrapper-10 {
    color: #000000;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 612px;
    white-space: nowrap;
  }
  
  .analytical-dashboard .overlap-wrapper {
    height: 670px;
    left: 383px;
    position: absolute;
    top: 69px;
    width: 49px;
  }
  
  .analytical-dashboard .overlap-2 {
    height: 670px;
    position: relative;
    width: 69px;
  }
  
  .analytical-dashboard .ellipse {
    background-color: #ff6b6b;
    border-radius: 24.5px;
    box-shadow: 0px 4px 4px #00000040;
    height: 49px;
    left: 0;
    position: absolute;
    top: 69px;
    width: 49px;
  }
  
  .analytical-dashboard .ellipse-2 {
    background-color: #ff6b6b;
    border-radius: 24.5px;
    box-shadow: 0px 4px 4px #00000040;
    height: 49px;
    left: 0;
    position: absolute;
    top: 138px;
    width: 49px;
  }
  
  .analytical-dashboard .ellipse-3 {
    background-color: #ff6b6b;
    border-radius: 24.5px;
    box-shadow: 0px 4px 4px #00000040;
    height: 49px;
    left: 0;
    position: absolute;
    top: 207px;
    width: 49px;
  }
  
  .analytical-dashboard .ellipse-4 {
    background-color: #ff6b6b;
    border-radius: 24.5px;
    box-shadow: 0px 4px 4px #00000040;
    height: 49px;
    left: 0;
    position: absolute;
    top: 276px;
    width: 49px;
  }
  
  .analytical-dashboard .ellipse-5 {
    background-color: #ff6b6b;
    border-radius: 24.5px;
    box-shadow: 0px 4px 4px #00000040;
    height: 49px;
    left: 0;
    position: absolute;
    top: 345px;
    width: 49px;
  }
  
  .analytical-dashboard .ellipse-6 {
    background-color: #ff6b6b;
    border-radius: 24.5px;
    box-shadow: 0px 4px 4px #00000040;
    height: 49px;
    left: 0;
    position: absolute;
    top: 414px;
    width: 49px;
  }
  
  .analytical-dashboard .ellipse-7 {
    background-color: #ff6b6b;
    border-radius: 24.5px;
    box-shadow: 0px 4px 4px #00000040;
    height: 49px;
    left: 0;
    position: absolute;
    top: 483px;
    width: 49px;
  }
  
  .analytical-dashboard .ellipse-8 {
    background-color: #ff6b6b;
    border-radius: 24.5px;
    box-shadow: 0px 4px 4px #00000040;
    height: 49px;
    left: 0;
    position: absolute;
    top: 621px;
    width: 49px;
  }
  
  .analytical-dashboard .ellipse-9 {
    background-color: #ff6b6b;
    border-radius: 24.5px;
    box-shadow: 0px 4px 4px #00000040;
    height: 49px;
    left: 0;
    position: absolute;
    top: 552px;
    width: 49px;
  }
  
  .analytical-dashboard .group-4 {
    height: 650px;
    left: 0;
    position: absolute;
    top: 0;
    width: 69px;
  }
  
  .analytical-dashboard .div-wrapper {
    background-color: #ff6b6b;
    border-radius: 24.5px;
    box-shadow: 0px 4px 4px #00000040;
    height: 49px;
    left: 0;
    position: absolute;
    top: 0;
    width: 49px;
  }
  
  .analytical-dashboard .text-wrapper-11 {
    color: #000000;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 20px;
    font-weight: 700;
    left: 12px;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 21px;
    white-space: nowrap;
  }
  
  .analytical-dashboard .text-wrapper-12 {
    color: #000000;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 20px;
    font-weight: 700;
    left: 21px;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 89px;
    white-space: nowrap;
  }
  
  .analytical-dashboard .text-wrapper-13 {
    color: #000000;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 20px;
    font-weight: 700;
    left: 19px;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 159px;
    white-space: nowrap;
  }
  
  .analytical-dashboard .text-wrapper-14 {
    color: #000000;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 20px;
    font-weight: 700;
    left: 19px;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 228px;
    white-space: nowrap;
  }
  
  .analytical-dashboard .text-wrapper-15 {
    color: #000000;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 20px;
    font-weight: 700;
    left: 13px;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 296px;
    white-space: nowrap;
  }
  
  .analytical-dashboard .text-wrapper-16 {
    color: #000000;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 20px;
    font-weight: 700;
    left: 13px;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 366px;
    white-space: nowrap;
  }
  
  .analytical-dashboard .text-wrapper-17 {
    color: #000000;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 20px;
    font-weight: 700;
    left: 15px;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 434px;
    white-space: nowrap;
  }
  
  .analytical-dashboard .text-wrapper-18 {
    color: #000000;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 20px;
    font-weight: 700;
    left: 13px;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 504px;
    white-space: nowrap;
  }
  
  .analytical-dashboard .text-wrapper-19 {
    color: #000000;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 20px;
    font-weight: 700;
    left: 18px;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 574px;
    white-space: nowrap;
  }
  
  .analytical-dashboard .text-wrapper-20 {
    color: #000000;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 20px;
    font-weight: 700;
    left: 17px;
    letter-spacing: 0;
    line-height: 8.9px;
    position: absolute;
    top: 641px;
    white-space: nowrap;
  }
  
  .analytical-dashboard .overlap-3 {
    background-color: #ffffff;
    box-shadow: 4px 0px 4px #00000040;
    height: 1024px;
    left: 0;
    position: absolute;
    top: 0;
    width: 96px;
  }
  
  .analytical-dashboard .frame-11 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 36px;
    left: 16px;
    position: relative;
    top: 14px;
    width: 64.42px;
  }
  
  .analytical-dashboard .image-removebg {
    height: 58px;
    position: relative;
    width: 56px;
  }
  
  .analytical-dashboard .line {
    align-self: stretch;
    height: 1px;
    object-fit: cover;
    position: relative;
    width: 100%;
  }
  
  .analytical-dashboard .frame-12 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .analytical-dashboard .icon-toggle-off {
    height: 24.88px;
    position: relative;
    width: 45.24px;
  }